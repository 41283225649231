:root {
  --primary-color: #10006F
}

/* general */

#visitor-registration {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.color-primary {
  color: var(--primary-color)
}

.main-container {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.center-items {
  display: flex;
  flex-direction: column;
  align-items: center;
}

h3, h4 {
  text-align: center;
}

.loader {
  border: 5px solid #f3f3f3; /* Light grey */
  border-top: 5px solid var(--primary-color); /* Blue */
  border-radius: 50%;
  width: 25px;
  height: 25px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}

/* Card styles: https://codepen.io/sdthornton/pen/wBZdXq */
.card {
  background: #fff;
  opacity: 0.98;
  border-radius: 2px;
  display: inline-block;
  position: relative;
  padding: 10px;
}

.card-1 {
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.card-1:hover {
box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-2 {
box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}

.card-3 {
box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23);
}

.card-4 {
box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.card-5 {
box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

/* Form css */
.registration-form {
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 600px;
}

.registration-form .check-in-btn {
  margin: 20px;
  width: 150px;
  height: 35px;
  border-radius: 5px;
  border: thin;
  background-color: var(--primary-color);
  color: white
}

.registration-form h3.location {
  color: var(--primary-color)
}

.registration-form p {
  text-align: center;
}

legend {
  text-align: center;
}

.input-field {
  margin-bottom: 7px;
}

.input-field input
{
  width: 100%;    
}

.registration-form fieldset {
  display: flex;
  flex-direction: column;    
}

/* Visitor Info */
.visitor-info .header {
  margin-bottom: 10px;
}

.visitor-info h3 {
  text-align: center;
  margin: 0px;
  color: var(--primary-color);
}

.visitor-info p {
  margin: 5px
}

.visitor-info .label {
  font-weight: bold;
}

/* Check In Form */
.check-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
}

.check-in-form h3 {
  text-align: center;
  margin: 5px;
}

.check-in-form .header-info p {
  text-align: center;
  font-size: 14px;
}

.check-in-form .form {
  display: flex;
  flex-direction: column;
}

.check-in-form .form .section {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.yes-no-field button, .yes-no-section button {
  margin-left: 10px;
  width: 75px;
  height: 35px;
  background-color: white;
  border-radius: 5px;
  border: none;
}

.check-in-form .yes-outline {
  border: solid;
  border-color: red;
  color: red;
}

.check-in-form .yes-fill {
  background-color: red;
  color: white
}

.check-in-form .no-outline {
  border: solid;
  border-color: green;
  color: green
}

.check-in-form .no-fill {
  background-color: green;
  color: white
}

.check-in-form .yes-no-field {
  display: flex;
  align-items: center;
  padding: 5px;
}

.check-in-form .yes-no-field h4 {
  display: flex;
  flex: 1;
  text-align: start;
  margin: 0px;
}

.check-in-form .yes-no-section {
  padding-bottom: 30px;
}

.check-in-form .yes-no-section .button-container {
  display: flex;
  justify-content: center;
}

.check-in-form .yes-no-section button {
  margin-left: 15px;
  margin-right: 15px;
  width: 100px;
}

.check-in-form .submit-btn {    
  margin-top: 20px;    
  border-radius: 5px;    
  height: 40px;
  border: thin;
  font-weight: bold;
}

.check-in-form .submit-btn.disabled {
  background-color: darkgrey;
  color: grey;
}

.check-in-form .submit-btn.enabled {
  background-color: var(--primary-color);
  color: white;
}

.check-in-form .submit-btn.submitted {
  background-color: green;
  color: white
}

.check-in-form .close-form-btn {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
  text-decoration: underline;
  color: var(--primary-color);
  cursor: pointer;
}